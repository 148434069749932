@import url("https://fonts.googleapis.com/css2?family=Coustard:wght@400;700&family=Space+Grotesk:wght@400;500;700&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Space+Grotesk:wght@500&display=swap");

@tailwind base;
@tailwind components;
@tailwind utilities;

body {
  @apply font-header-font;
}

.selected-date-highlight {
  background-color: #ff9519 !important;
  color: #fff !important;
  border-radius: 10% !important;
}

.highlighted-event-date {
  background-color: #029e9b !important;
  color: #fff !important;
  border-radius: 10% !important;
}

.today-date-highlight {
  border: 2px solid;
  background-color: white;
  color: #fff !important;
  border-radius: 10% !important;
}

.react-datepicker__day--keyboard-selected {
  background-color: white !important;
  color: black !important;
}
